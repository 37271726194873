<template>
  <div style="padding-top:60px">
    <div class="download-box">
      <div class="center-box">
        <div class="download-content">
          <div class="c-box download-l">
            <img src="../assets/images/pc/logo.png"
                 alt="">
            <div>海豚管家</div>
            <div>多店铺运营管理工具</div>
            <div class="download-btn">
              <div id="second"
                   class="buttonBox">
                <button @click="dowload('ExeDownloadUrl')">
                  <img src="../assets/images/pc/download.png">
                  下载32位版本
                </button>
                <div class="border"></div>
                <div class="border"></div>
              </div>

              <div id="second"
                   class="buttonBox download-btn">
                <button @click="dowload('ExeDownloadUrl64')">
                  <img src="../assets/images/pc/download.png">
                  下载64位版本
                </button>
                <div class="border"></div>
                <div class="border"></div>
              </div>
            </div>
          </div>

          <div class="c-box download-r">
            <img src="../assets/images/pc/xiazai.png"
                 alt="">
            <div>网络共享软件</div>
            <div>网络共享辅助工具，出差可以很便捷使用您常用的家庭网络</div>
            <div class="download-btn">
              <div id="second"
                   class="buttonBox">
                <button @click="dowload('InterShareApp')">
                  <img src="../assets/images/pc/download.png">
                  下载软件
                </button>
                <div class="border"></div>
                <div class="border"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

import '../assets/css/style.css'
export default {
  data () {
    return {
      ExeDownloadUrl: window.PLATFROM_CONFIG.ExeDownloadUrl,
      ExeDownloadUrl64: window.PLATFROM_CONFIG.ExeDownloadUrl64,
      InterShareApp: window.PLATFROM_CONFIG.InterShareApp,
    }
  },
  methods: {
    dowload (Url) {
      window.location.href = this[Url]
    },
  }
}
</script>
<style lang="less" scoped>
button {
  width: 200px;
  position: relative;
  background: rgba(255, 255, 255, 0.27);
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  font-size: 15px;
  outline: none;
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

.download-btn {
  position: relative;
  overflow: hidden;
  margin: 20px 0;
  > div:nth-child(1) {
    float: left;
  }
  > div:nth-child(2) {
    float: right;
  }
  button {
    width: 200px;
    height: 45px;
    line-height: 45px;
    background: #3cb371;
    cursor: pointer;
    border-radius: 3px;
  }
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 6px;
  }
}

.download-content > div:nth-child(1) {
  float: left;
}
.download-content > div:nth-child(2) {
  float: right;
}
.download-r {
  .download-btn {
    left: 50%;
    transform: translateX(-100px);
  }
}
.buttonBox {
  margin: 0;
}
.download-content {
  .c-box {
    width: 570px;
    height: 444px;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    padding: 40px 50px;
    margin-top: 80px;
    > img {
      width: 100px;
      height: 100px;
      margin-top: 40px;
    }
    > div:nth-child(2) {
      font-size: 24px;
      font-weight: bold;
      margin-top: 30px;
      color: #000;
    }
    > div:nth-child(3) {
      font-size: 16px;
      color: #666;
      width: 300px;
      height: 50px;
      margin: 15px auto;
    }
  }
}
</style>
